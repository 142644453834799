import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col, Nav, Accordion, Tabs} from "react-bootstrap";
import {sleep} from "./AddBrandModal";
import {Trash} from "react-feather";
import { Spinner } from 'reactstrap';

export default class UpdateModalForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstImg: null,
            imgsSelected: [],
            selectedProductToModify: null,
            newDeclinaisonMerchandName: "",
            newDeclinationGTIN: "",
            newDeclinaisonMerchandUrl: "",
            newDeclination: {
                color : "",
                firstImg: "",
                price: 0.0,
                reference: "",
                retailers: [],
                imgs: []
            },
            isValidated: false,
            numberOfCharMetaTitle: 0,
            numberOfCharMetaDescription:0,
            numberOfNewDeclination: 1
        };
        this.uploadImgs = this.uploadImgs.bind(this);
        this.uploadFirstImg = this.uploadFirstImg.bind(this);
    }

    handleClose = (selectedProduct) => (
        fetch('https://back-products-api.therunningcollective.fr/product', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(selectedProduct)
        })
            .then(async response =>
                await sleep(2000).then(
                    this.props.closeModal,
                    window.location.reload(false)
                ),
            )
            .catch(async response =>
                await sleep(2000).then(
                    this.props.closeModal,
                    window.location.reload(false)
                ),
            )

        );

    addNewMerchandDeclinaison(declinaiton){
        if (!declinaiton.analysedRetailers){
            declinaiton.analysedRetailers = []
        }
        declinaiton.analysedRetailers.push({
            retailerName: this.state.newDeclinaisonMerchandName,
            url: this.state.newDeclinaisonMerchandUrl
        })
        this.setState({newDeclinaisonMerchandName: ""})
        this.setState({newDeclinaisonMerchandUrl: ""})
    }

    addNewGTINDeclinaison(declinaiton){
        if (!declinaiton.gtins){
            declinaiton.gtins = []
        }
        console.log(this.state.newDeclinationGTIN)
        declinaiton.gtins.push(this.state.newDeclinationGTIN)
        this.setState({newDeclinationGTIN: ""})
    }


    removeMerchandDeclinaison(declinaiton, name){
        let list = declinaiton.analysedRetailers.filter((retailer, index) => retailer.retailerName !== name)
        declinaiton.analysedRetailers = list
        this.setState({newDeclinaisonMerchandName: ""})
        this.setState({newDeclinaisonMerchandUrl: ""})
    }

    removeGTINDeclinaison(declinaiton, gtin){
        let list = declinaiton.gtins.filter((value, index) => gtin !== value)
        declinaiton.gtins = list
        this.setState({newDeclinationGTIN: ""})
    }

    uploadFirstImg(e, declinaiton) {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "products/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        declinaiton.firstImg = result.message
                        this.setState({firstImg:  result.message});
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    uploadImgs(e, declinaiton) {
        if (e.target.files.length > 0) {
            for (var i = 0; i < e.target.files.length; i++) {
                // on récupère le i-ème fichier

                const file = e.target.files.item(i);
        const fileName = e.target.files.item(i).name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "products/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        this.setState({imgsSelected: [...this.state.imgsSelected, result.message]});
                        sleep(1000)
                        declinaiton.imgs = this.state.imgsSelected
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
            }
        }
        }
    }


    addNewDeclination(product){
        product.declinations.push(this.state.newDeclination)
        this.setState({numberOfNewDeclination: product.declinations.length})
    }

    removeDeclination(product, reference){
        let list = product.declinations.filter((declination, index) => declination.reference !== reference)
        product.declinations = list
        let nb = this.state.numberOfNewDeclination
        nb--
        this.setState({numberOfNewDeclination: nb})
    }

    render(){
        this.props.selectedProduct.headCategory = this.props.headCategory
        this.props.selectedProduct.order = this.props.order
        console.log(this.props.selectedProduct)

        if (this.props.selectedProduct.pimsterProductName == ""){
            this.props.selectedProduct.pimsterProductName = "page-produits"
        }

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Modifier un article</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Informations générales</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Coloris </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">SEO </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="posts">Articles </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pimster">Pimster</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => (this.props.selectedProduct.name = e.target.value, this.props.selectedProduct.shortURL = slugify(e.target.value))} defaultValue={this.props.selectedProduct.name} placeholder="Ex : Nike Pegasus 37"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Description : </Form.Label>
                                            <Form.Control as="textarea" rows={4} onChange={(e) => this.props.selectedProduct.description = e.target.value} defaultValue={this.props.selectedProduct.description} placeholder="Cette chaussure est ..."/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Poids : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedProduct.weigh = e.target.value} defaultValue={this.props.selectedProduct.weigh} placeholder="130"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Drop : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedProduct.drop = e.target.value} defaultValue={this.props.selectedProduct.drop} placeholder="10"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Stack : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedProduct.stack = e.target.value} defaultValue={this.props.selectedProduct.stack} placeholder="18mm"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Nombre max de kms : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedProduct.maxkm = e.target.value} defaultValue={this.props.selectedProduct.maxkm} placeholder="400"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Marque : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedProduct.brand = e.target.value}
                                                defaultValue={this.props.selectedProduct.brand}
                                            >
                                                <option >Ajouter...</option>
                                                {this.props.availableBrands.map(brand =>(
                                                    <option key={brand.name} value={brand.name}>{brand.name}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Catégorie parente : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedProduct.headCategory = e.target.value}
                                                defaultValue={this.props.selectedProduct.headCategory}
                                            >
                                                <option value="/products/shoes">Chaussures</option>
                                                <option value="/products/spikes">Pointes</option>
                                                <option value="/products/apparel">Vêtements</option>
                                                <option value="/products/accessories">Accessoires</option>
                                                <option value="/products/electronic">Electronique</option>
                                                <option value="/products/nutrition">Nutrition</option>
                                                <option value="/products/vouchers">Carte cadeau</option>


                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Sexe : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                onChange={(e) => this.props.selectedProduct.gender = e.target.value}
                                                defaultValue={this.props.selectedProduct.gender}
                                            >
                                                <option >Ajouter...</option>
                                                <option value="Men">Homme</option>
                                                <option value="Women">Femme</option>
                                                <option value="Unisex">Unisexe</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Catégories</Form.Label>
                                            <Form.Control
                                                as="select"
                                                multiple
                                                onChange={(e) => this.props.selectedProduct.categories = [...e.target.selectedOptions].map(o => o.value)}
                                                defaultValue={this.props.selectedProduct.categories}
                                            >
                                                {(this.props.availableCategories) ? this.props.availableCategories.map(category =>
                                                    <option key={category} value={category}>{category}</option>
                                                ) : <option>Aucunes catégories disponibles</option>}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Type de produit : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                multiple
                                                onChange={(e) => this.props.selectedProduct.shopType = [...e.target.selectedOptions].map(o => o.value)}
                                                defaultValue={this.props.selectedProduct.shopType}
                                            >
                                                <option key="athletisme" value="athletisme">Athlétisme</option>
                                                <option key="running" value="running">Running</option>
                                                <option key="trail" value="trail">Trail</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Produit prioritaire : </Form.Label>
                                            <Form.Check aria-label="priorityProduct"
                                                        defaultChecked={this.props.selectedProduct.priorityProduct}
                                                        onChange={(e) => this.props.selectedProduct.priorityProduct = e.target.checked} />
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Disponible prochainement : </Form.Label>
                                            <Form.Check aria-label="availableSoon"
                                                        defaultChecked={this.props.selectedProduct.availableSoon}
                                                        onChange={(e) => this.props.selectedProduct.availableSoon = e.target.checked} />
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Afficher sur le site : </Form.Label>
                                            <Form.Check aria-label="showProduct"
                                                        defaultChecked={this.props.selectedProduct.showProduct}
                                                        onChange={(e) => this.props.selectedProduct.showProduct = e.target.checked} />
                                        </Form.Group>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <Tabs id="uncontrolled-tab-example">
                                            {this.props.selectedProduct.declinations.map((declinaiton, index) =>(
                                                    <Tab key={index} eventKey={index} title={index}>
                                                        <Form.Group >
                                                            <Form.Label>Coloris : </Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                defaultValue={declinaiton.color}
                                                                onChange={(e) => declinaiton.color = e.target.value}
                                                            >
                                                                <option >Ajouter...</option>
                                                                {this.props.availableColors.map(color =>
                                                                    <option key={color.name} value={color.name}>{color.name}</option>
                                                                )}
                                                            </Form.Control>
                                                        </Form.Group >
                                                        <Form.Group >
                                                            <Form.Label>Référence : </Form.Label>
                                                            <Form.Control type="text" onChange={(e) => declinaiton.reference = e.target.value} defaultValue={declinaiton.reference} placeholder="123456"/>
                                                        </Form.Group>
                                                        <Form.Group >
                                                            <Form.Label>Prix : (€ TTC) </Form.Label>
                                                            <Form.Control type="text" onChange={(e) => declinaiton.price = parseFloat(e.target.value)} defaultValue={declinaiton.price} placeholder="123456"/>
                                                        </Form.Group>
                                                        <Form.Group >
                                                            <Form.Label> Disponible prochainement : </Form.Label>
                                                            <Form.Check aria-label="availableSoon"
                                                                        defaultChecked={declinaiton.availableSoon}
                                                                        onChange={(e) => declinaiton.availableSoon = e.target.checked} />
                                                        </Form.Group>
                                                        <Form.Group >
                                                            <Form.Label>Image de couverture : </Form.Label>
                                                            <Form.File
                                                                id="custom-first-img-translate-html"
                                                                label="Ajouter un image"
                                                                data-browse="Ajouter"
                                                                onChange= {(e) => {this.uploadFirstImg(e, declinaiton)}}
                                                                custom
                                                            />
                                                            {declinaiton.firstImg ?
                                                                    <img className="imgToDisplay" alt="Couverture actuelle" src={declinaiton.firstImg}/>
                                                                :
                                                                null
                                                            }
                                                        </Form.Group>
                                                        <Form.Group >
                                                            <Form.Label>Autres images : </Form.Label>
                                                            <Form.File
                                                                id="custom-file-translate-html"
                                                                label="Ajouter un image"
                                                                data-browse="Ajouter"
                                                                onChange= {(e) => {this.uploadImgs(e, declinaiton)}}
                                                                custom
                                                                multiple
                                                            />
                                                            {declinaiton.imgs ?
                                                                declinaiton.imgs.map(img => (
                                                                    <img className="imgToDisplay" key={img} alt="Autres images actuelles" src={img}/>
                                                                ))
                                                                :
                                                                null
                                                            }
                                                        </Form.Group>
                                                        <Form.Group >
                                                            <Form.Label>Codes barres : </Form.Label>
                                                            <Accordion>
                                                                    <Form>
                                                                        {declinaiton.gtins && declinaiton.gtins.map(gtin =>(
                                                                            <Form.Group as={Row} key={gtin}>
                                                                                <Col sm="7" className="retailerUrl">
                                                                                    <Form.Control type="text" onChange={(e) => gtin = e.target.value} defaultValue={gtin} placeholder="1234567"/>
                                                                                </Col>
                                                                                <Col sm="1" className="retailerUrl">
                                                                                    <Button variant="secondary" onClick={() => this.removeGTINDeclinaison(declinaiton, gtin)}>
                                                                                        <Trash size={10}/>
                                                                                    </Button>
                                                                                </Col>

                                                                            </Form.Group>
                                                                        ))}
                                                                        <Form.Group as={Row}>
                                                                            <Col sm="7" className="retailerUrl">
                                                                                <Form.Control type="text"  placeholder="1234567"
                                                                                              onChange={(e) => this.setState({newDeclinationGTIN : e.target.value}) }
                                                                                />
                                                                            </Col>
                                                                            <Col sm="1" className="retailerUrl">
                                                                                <Button variant="secondary" onClick={() => this.addNewGTINDeclinaison(declinaiton)}>+</Button>
                                                                            </Col>
                                                                        </Form.Group>
                                                                    </Form>
                                                            </Accordion>
                                                        </Form.Group>
                                                        <Form.Group >
                                                            <Form.Label>Vendeurs : </Form.Label>
                                                            <Accordion>
                                                                    <Form>
                                                                        {declinaiton.analysedRetailers && declinaiton.analysedRetailers.map(retailer =>(
                                                                            <Form.Group as={Row} key={retailer.retailerName}>
                                                                                <Form.Label column sm="4">
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        defaultValue={retailer.retailerName}
                                                                                        onChange={(e) => retailer.retailerName = e.target.value}
                                                                                    >
                                                                                        {this.props.availableMerchands.map(merchand =>
                                                                                            <option value={merchand.name} key={merchand.name}>{merchand.name}</option>
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Label>
                                                                                <Col sm="7" className="retailerUrl">
                                                                                    <Form.Control type="text" onChange={(e) => retailer.url = e.target.value} defaultValue={retailer.url} placeholder="https://....."/>
                                                                                </Col>
                                                                                <Col sm="1" className="retailerUrl">
                                                                                    <Button variant="secondary" onClick={() => this.removeMerchandDeclinaison(declinaiton, retailer.retailerName)}>
                                                                                        <Trash size={10}/>
                                                                                    </Button>
                                                                                </Col>

                                                                            </Form.Group>
                                                                        ))}
                                                                        <Form.Group as={Row}>
                                                                            <Form.Label column sm="4">
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    defaultValue="Ajouter..."
                                                                                    onChange={(e) => this.setState({newDeclinaisonMerchandName : e.target.value}) }
                                                                                >
                                                                                    <option >Ajouter...</option>
                                                                                    {this.props.availableMerchands.map(merchand =>
                                                                                        <option key={merchand.name} value={merchand.name}>{merchand.name}</option>
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Form.Label>
                                                                            <Col sm="7" className="retailerUrl">
                                                                                <Form.Control type="text"  placeholder="https://...."
                                                                                              onChange={(e) => this.setState({newDeclinaisonMerchandUrl : e.target.value}) }
                                                                                />
                                                                            </Col>
                                                                            <Col sm="1" className="retailerUrl">
                                                                                <Button variant="secondary" onClick={() => this.addNewMerchandDeclinaison(declinaiton)}>+</Button>
                                                                            </Col>
                                                                        </Form.Group>
                                                                        <Form.Group>
                                                                            <Button variant="secondary" onClick={() => this.removeDeclination(this.props.selectedProduct, declinaiton.reference)}>Supprimer le coloris</Button>
                                                                        </Form.Group>
                                                                    </Form>
                                                            </Accordion>
                                                        </Form.Group>
                                                    </Tab>
                                            ))}
                                            <Tab eventKey={this.props.selectedProduct.declinations.length} title={<Button variant= "secondary" onClick={() => this.addNewDeclination(this.props.selectedProduct)}>+</Button>}>
                                            </Tab>
                                        </Tabs>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <Form.Group >
                                            <Form.Label>Balise Titre : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => {this.props.selectedProduct.metaTitle = e.target.value; this.setState({numberOfCharMetaTitle: e.target.value})}} defaultValue={this.props.selectedProduct.metaTitle ? this.props.selectedProduct.metaTitle : this.props.selectedProduct.name} placeholder="Ex : Nike Pegasus 37"/>
                                            <Form.Text className="text-muted">
                                                {this.state.numberOfCharMetaTitle ? this.state.numberOfCharMetaTitle.length : this.props.selectedProduct.metaTitle.length} des 70 caractères utilisés (recommandé)
                                            </Form.Text>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Meta Description : </Form.Label>
                                            <Form.Control as="textarea" rows={4} onChange={(e) => {this.props.selectedProduct.metaDescription = e.target.value; this.setState({numberOfCharMetaDescription: e.target.value})}} defaultValue={this.props.selectedProduct.metaDescription ? this.props.selectedProduct.metaDescription : this.props.selectedProduct.description} placeholder="Cette chaussure est ..."/>
                                            <Form.Text className="text-muted">
                                                {this.state.numberOfCharMetaDescription ? this.state.numberOfCharMetaDescription.length : this.props.selectedProduct.metaDescription.length} des 160 caractères utilisés (recommandé)
                                            </Form.Text>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>URL simplifiée : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedProduct.shortURL = e.target.value} defaultValue={this.props.selectedProduct.shortURL ? this.props.selectedProduct.shortURL : slugify(this.props.selectedProduct.name)} placeholder="pegasus-37"/>
                                        </Form.Group>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="posts">
                                        <Form.Group >
                                            <Form.Label>Articles liés au produit : </Form.Label>
                                            <Form.Control
                                                as="select"
                                                multiple
                                                onChange={(e) => this.props.selectedProduct.posts = [...e.target.selectedOptions].map(o => o.value)}
                                                defaultValue={this.props.selectedProduct.posts}
                                            >
                                                {(this.props.availablePosts) ? this.props.availablePosts.map(post =>
                                                    <option key={post.slug} value={post.slug}>{post.title.rendered}</option>
                                                ) : <option>Aucun article disponible</option>}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Article à afficher sous le produit : </Form.Label>
                                            <Form.Control
                                                as="select" 
                                                defaultValue={this.props.selectedProduct.blogIntegratedShortURL}
                                                onChange={(e) => this.props.selectedProduct.blogIntegratedShortURL = e.target.value}
                                            >
                                                {(this.props.availablePosts) ? this.props.availablePosts.map(post =>
                                                    <option key={post.slug} value={post.slug}>{post.title.rendered}</option>
                                                ) : <option>Aucun article disponible</option>}
                                            </Form.Control>
                                        </Form.Group >
                                        <Form.Group >
                                            <Form.Label>Produit approuvé pour l'entrainement : </Form.Label>
                                            <Form.Check aria-label="trainingApproved"
                                                        defaultChecked={this.props.selectedProduct.trainingApproved}
                                                        onChange={(e) => this.props.selectedProduct.trainingApproved = e.target.checked} />
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Produit ayant réalisé un record du monde : </Form.Label>
                                            <Form.Check aria-label="raceApproved"
                                                        defaultChecked={this.props.selectedProduct.raceApproved}
                                                        onChange={(e) => this.props.selectedProduct.raceApproved = e.target.checked} />
                                        </Form.Group>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="pimster">
                                        <Form.Group >
                                            <Form.Label>Product Name : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => {this.props.selectedProduct.pimsterProductName = e.target.value}} defaultValue={this.props.selectedProduct.pimsterProductName != "" ? this.props.selectedProduct.pimsterProductName : "page-produits"} placeholder="Ex : nike-pegasus-41"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Module ID : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => {this.props.selectedProduct.pimsterModuleID = e.target.value}} defaultValue={this.props.selectedProduct.pimsterModuleID} placeholder="Ex : 445"/>
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        this.state.isValidated ?
                        <div style={{textAlign: 'center'}}>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden"></span>
                            </Spinner>
                        </div>
                        : <>
                        <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                            Annuler
                        </Button>
                        <Button variant="primary" type="submit" onClick={() => this.handleClose(this.props.selectedProduct, this.setState({isValidated: true}))}>
                            Valider
                        </Button>
                        </>
                    }
                    
                </Modal.Footer>
            </Modal>
        )
    }
}

export function slugify(string) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
}
