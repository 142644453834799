import async from "../components/Async";

import {
  Grid as GridIcon,
  Layout as LayoutIcon,
  Users as UsersIcon,
  BookOpen as BookOpenIcon,
  Users,
  Cloud,
  DollarSign,
  Moon,
  Folder,
  PenTool,
  Code,
  Speaker,
  MessageCircle,
  Thermometer, Trello, Star, Octagon, Gift, Phone, Eye, CreditCard, ExternalLink, Key, Flag, Globe, UserCheck, Sunrise, ShoppingCart,
  Archive

} from "react-feather";

// Pages
import Settings from "../pages/pages/Settings";
import Logout from "../components/Logout";
import SignIn from "../pages/auth/SignIn";
import {CategoriesList} from "../components/CategoriesList";
import {ColorsList} from "../components/ColorsList";
import {BrandsList} from "../components/BrandsList";
import {MerchandsList} from "../components/MerchandsList";
import {VouchersList} from "../components/VouchersList";
import {UsersList} from "../components/UsersList";
import {ProductsList} from "../components/ProductsList";
import {AppearancePageList} from "../components/AppearancePageList";
import {NewsletterProductsPageList} from "../components/NewsletterProductsPageList";
import {NewsletterPageList} from "../components/NewsletterPageList";
import {PodcastsList} from "../components/PodcastsList";
import {NewsletterPricesPageList} from "../components/NewsletterPricesPageList";
import {NewsletterPricesGlobalPageList} from "../components/NewsletterPricesGlobalPageList";
import {AdidasVouchersList} from "../components/AdidasVouchersList";
import {ProductRatingsList} from "../components/ProductRatingsList";
import {DetailedReporting} from "../components/DetailedReportingList";
import {DeclinationHistoryPricesList} from "../components/DeclinationHistoricPrice";
import {DetailedUserDataList} from "../components/DetailedUserDataList";
import {AppearanceAthletismePageList} from "../components/AppearanceAthletismePageList";
import {DetailedUserClientDataList} from "../components/DetailedUserClientDataList";
import {FrontUsersList} from "../components/FrontUsersList";
import {GamesList} from "../components/GamesList";
import {RewardsList} from "../components/RewardsList";
import {PushNotificationsList} from "../components/PushNotificationsList";
import {CoinsList} from "../components/CoinsList";
import {DynamicLinksList} from "../components/DynamicLinksList";
import {ProductsFeedList} from "../components/ProductsFeedList";
import {DetailedProductsReporting} from "../components/DetailedProductsReportingList";
import {LandingPagesList} from "../components/LandingPagesList";
import {LandingPagesContentList} from "../components/LandingPagesContentList";
import {PushNotificationsSentList} from "../components/PushNotificationsSentList";
import {MerchantPlacesList} from "../components/MerchantPlaceList";
import {VouchersInfoList} from "../components/VouchersInfoList";
import {FrontUsersFavoritesList} from "../components/FrontUsersFavoritesList";
import {LanguagesList} from "../components/LanguagesList";
import {RacesList} from "../components/RacesList";
import {AttendeeRewardsList} from "../components/AttendeeRewardsList";
import {WheelSpinList} from "../components/WheelSpinsList";
import {WheelSpinLinksList} from "../components/WheelSpinsLinksList";
import {TestProductsUsersList} from "../components/TestProductsUsersList";
import {EventsList} from "../components/EventsList";
import {AttendeeEventsList} from "../components/AttendeeEventsList"
import {StravaAthleteList} from "../components/StravaAthleteList";
import {PhysicalShopsList} from "../components/PhysicalShopsList";
import {PhysicalShopActivationsList} from "../components/PhysicalShopActivationsList";
import {PhysicalShopOptionsList} from "../components/PhysicalShopOptionsList";

import { QRCodesList } from "../components/QRCodesList";
import {ForecastsList} from "../components/ForecastsList";
import { ForecastContentsList } from "../components/ForecastContentsList";
import { ForecastAthletesList } from "../components/ForecastAthletesList";
import { ForecastContentResultsList } from "../components/ForecastContentResultsList";
import { FriendsReferredList } from "../components/FriendsReferredList";
import { ForecastLeaguesList } from "../components/ForecastLeaguesList";
import { AttendeeLeaguesList } from "../components/AttendeeForecastsList";
import { AmbassadorAppliedUsersList } from "../components/AmbassadorAppliedUsersList";
import { AmbassadorApprovedUsersList } from "../components/AmbassadorApprovedUsersList";
// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));

const dashboardRoutes = {
  path: "/",
  name: "Accueil",
  badgeColor: "primary",
  icon: LayoutIcon,
  containsHome: true,
  component: Default
};

const dashboardAthletismeRoutes = {
  path: "/athletisme",
  name: "Accueil",
  badgeColor: "primary",
  icon: LayoutIcon,
  containsHome: true,
  component: Default
};

const dashboardTrailRoutes = {
  path: "/trail",
  name: "Accueil",
  badgeColor: "primary",
  icon: LayoutIcon,
  containsHome: true,
  component: Default
};

const dashboardRunningRoutes = {
  path: "/running",
  name: "Accueil",
  badgeColor: "primary",
  icon: LayoutIcon,
  containsHome: true,
  component: Default
};

const loginRoutes = {
  path: "/login",
  name: "Connexion",
  badgeColor: "primary",
  icon: LayoutIcon,
  containsHome: true,
  component: SignIn
};

const detailedReportingRoutes = {
  path: "/detailed-reporting",
  name: "Reporting détaillé",
  icon: Thermometer,
  children: [
    {
      path: "/detailed-reporting/transactions",
      name: "Transactions",
      component: DetailedReporting
    },
    {
      path:"/detailed-reporting/products",
      name: "Produits les plus vendus",
      component: DetailedProductsReporting
    },
    {
      path: "/detailed-reporting/users",
      name: "Pages visionnées",
      component: DetailedUserDataList
    },
    {
      path: "/detailed-reporting/users-clients",
      name: "Pages visionnées (clients)",
      component: DetailedUserClientDataList
    },
  ]
};

const declinationHistoryPriceRoutes = {
  path: "/declination-history-prices",
  name: "Historique des prix",
  icon: Trello,
  component: DeclinationHistoryPricesList,
};

const pageRoutes = {
  path: "/products",
  name: "Produits",
  icon: GridIcon,
  children: [
    {
      path: "/products/shoes",
      name: "Chaussures",
      component: ProductsList
    },
    {
      path: "/products/spikes",
      name: "Pointes",
      component: ProductsList
    },
    {
      path: "/products/apparel",
      name: "Vêtements",
      component: ProductsList
    },
    {
      path: "/products/accessories",
      name: "Accessoires",
      component: ProductsList
    },
    {
      path: "/products/nutrition",
      name: "Diététique",
      component: ProductsList
    },
    {
      path: "/products/electronic",
      name: "Électronique",
      component: ProductsList
    },
    {
      path: "/products/vouchers",
      name: "Cartes cadeau",
      component: ProductsList
    },
  ]
};

const categoryRoutes = {
  path: "/category",
  name: "Catégories",
  icon: BookOpenIcon,
  children: [
      {
        path: "/category/shoes",
        name: "Chaussures",
        component: CategoriesList
      },
      {
        path: "/category/spikes",
        name: "Pointes",
        component: CategoriesList
      },
      {
        path: "/category/apparel",
        name: "Vêtements",
        component: CategoriesList
      },
      {
        path: "/category/accessories",
        name: "Accessoires",
        component: CategoriesList
      },
      {
        path: "/category/nutrition",
        name: "Diététique",
        component: CategoriesList
      },
      {
        path: "/category/electronic",
        name: "Électronique",
        component: CategoriesList
      },
    {
      path: "/category/vouchers",
      name: "Cartes cadeau",
      component: CategoriesList
    },
    ]
};

const declinaisonsRoutes = {
  path: "/declinaison",
  name: "Déclinaisons",
  icon: Moon,
  children: [
    {
      path: "/declinaison/colors",
      name: "Coloris",
      component: ColorsList
    },
    {
      path: "/declinaison/brands",
      name: "Marques",
      component: BrandsList
    },
    {
      path: "/declinaison/languages",
      name: "Langues",
      component: LanguagesList
    },

  ]
};

const usersRoutes = {
  path: "/users",
  name: "Utilisateurs",
  icon: Users,
  restricted: "superadmin",
  component: UsersList,
};

const physicalShopsRoutes = {
  path: "/physical-shop",
  name: "Magasins Physiques",
  icon: ShoppingCart,
  children: [
    {
      path: "/physical-shop/list",
      name: "Liste",
      component: PhysicalShopsList
    },
    {
      path: "/physical-shop/activations",
      name: "Activations",
      component: PhysicalShopActivationsList
    },
    {
      path: "/physical-shop/options",
      name: "Offres",
      component: PhysicalShopOptionsList
    }
  ]
};

const merchandsRoutes = {
  path: "/merchands",
  name: "Sites marchands",
  icon: Cloud,
  component: MerchandsList,
};

const vouchersRoutes = {
  path: "/vouchers",
  name: "Coupons de réduction",
  icon: DollarSign,
  children: [
    {
      path: "/vouchers/global",
      name: "Coupons de réductions - Généraux",
      component: VouchersList
    },
    {
      path: "/vouchers/adidas",
      name: "Coupons de réductions - Adidas",
      component: AdidasVouchersList
    },
    {
      path: "/vouchers/info",
      name: "Coupons de réductions - Infos",
      component: VouchersInfoList
    }
  ]
};

const friendsReferredRoutes = {
  path: "/friends-referred",
  name: "Codes de parrainage",
  icon: Eye,
  component: FriendsReferredList,
};

const podcastsRoutes = {
  path: "/podcasts",
  name: "Podcasts",
  icon: Speaker,
  component: PodcastsList,
};

const appearanceRoutes = {
  path: "/appearance",
  name: "Apparence",
  icon: PenTool,
  children: [
    {
      path: "/appearance/home/slider",
      name: "Slider - Page d'accueil",
      component: AppearancePageList
    },
    {
      path: "/appearance/home/banner",
      name: "Banner - Page d'accueil",
      component: AppearancePageList
    },
    {
      path: "/appearance/home/best-products",
      name: "Produits - Page d'accueil",
      component: AppearancePageList
    },
    {
      path: "/appearance/home/infinite-products",
      name: "Mise en avant infinie - Page d'accueil",
      component: AppearancePageList
    },
    {
      path: "/appearance/home/profil",
      name: "Profil - Page d'accueil",
      component: AppearancePageList
    },
    {
      path: "/appearance/home/search",
      name: "Profil - Recherche",
      component: AppearancePageList
    },
    {
      path: "/appearance/home/search-background",
      name: "Arrière plan Profil - Recherche",
      component: AppearancePageList
    },
    {
      path: "/appearance/home/search-category",
      name: "Catégories - Recherche",
      component: AppearancePageList
    },
    {
      path: "/appearance/home/search-category-V2",
      name: "Catégories V2 - Recherche",
      component: AppearancePageList
    }
  ]
};

const newsletterRoutes = {
  path: "/newsletter",
  name: "Newsletter",
  icon: Folder,
  children: [
    {
      path: "/newsletter/products",
      name: "Produits recherchés",
      component: NewsletterProductsPageList
    },
    {
      path: "/newsletter/prices-global",
      name: "Prix recherchés - global",
      component: NewsletterPricesGlobalPageList
    },
    {
      path: "/newsletter/prices",
      name: "Prix recherchés - détails",
      component: NewsletterPricesPageList
    },
    {
      path: "/newsletter/clients",
      name: "Mail collectés",
      component: NewsletterPageList
    }
  ]
};

const landingPagesRoutes = {
  path: "/landing-pages",
  name: "Landing Pages",
  icon: Flag,
  children: [
    {
      path: "/landing-pages",
      name: "Déclaration des pages",
      component: LandingPagesList
    },
    {
      path: "/landing-pages-content",
      name: "Contenu des pages",
      component: LandingPagesContentList
    }
  ]
};

const productRatingsRoutes = {
  path: "/product-rating",
  name: "Avis produits",
  icon: MessageCircle,
  component: ProductRatingsList,
};

const profileRoutes = {
  path: "/profile",
  name: "Profile",
  icon: UsersIcon,
  component: Settings,
};

const logoutRoutes = {
  path: "/logout",
  name: "Logout",
  icon: UsersIcon,
  component: Logout,
};

const clientsRoutes = {
  path: "/clients",
  name: "Comptes clients",
  icon: Star,
  component: FrontUsersList,
};

const stravaAthleteRoutes = {
  path: "/strava-athletes",
  name: "Comptes Strava",
  icon: Sunrise,
  component: StravaAthleteList,
};

const gamesRoutes = {
  path: "/games",
  name: "Jeux Concours",
  icon: Octagon,
  component: GamesList,
};

const rewardsRoutes = {
  path: "/rewards",
  name: "Récompenses",
  icon: Gift,
  component: RewardsList,
};

const notificationsRoutes = {
  path: "/notifications",
  name: "Push Notifications",
  icon: Phone,
  children: [
    {
      path: "/notifications",
      name: "A programmer",
      component: PushNotificationsList
    },
    {
      path: "/notifications-sent",
      name: "Envoyées",
      component: PushNotificationsSentList
    },
  ]
};

const coinsRoutes = {
  path: "/coins",
  name: "TRCoins",
  icon: CreditCard,
  component: CoinsList,
};

const dynamicLinksRoutes = {
  path: "/dynamic-links",
  name: "Dynamic Links",
  icon: ExternalLink,
  component: DynamicLinksList,
};

const favoritesUsersRoutes = {
  path: "/favorites-users",
  name: "Produits favoris",
  icon: Star,
  component: FrontUsersFavoritesList,
};

const merchantPlacesRoutes = {
  path: "/merchant-place",
  name: "Offres CornerTRC",
  icon: Cloud,
  component: MerchantPlacesList,
};

const wheelsSpinRoutes = {
  path: "/wheel-spin",
  name: "Roue de la fortune",
  icon: Globe,
  children: [
    {
      path: "/wheel-spin/configuration",
      name: "Configuration",
      component: WheelSpinList
    },
    {
      path: "/wheel-spin/links",
      name: "Liens générés",
      component: WheelSpinLinksList
    },
  ]
};

const physicalEventsRoutes = {
  path: "/physical-events",
  name: "Evenements physiques",
  icon: Moon,
  children: [
    {
      path: "/physical-events/list",
      name: "Liste",
      component: EventsList
    },
    {
      path: "/physical-events/attendees",
      name: "Participants",
      component: AttendeeEventsList
    },
  ]
};

const forecastsRoutes = {
  path: "/forecasts",
  name: "Pronostics",
  icon: Moon,
  children: [
    {
      path: "/forecasts/list",
      name: "Liste",
      component: ForecastsList
    },
    {
      path: "/forecasts/athletes",
      name: "Athlètes",
      component: ForecastAthletesList
    },
    {
      path: "/forecasts/contents",
      name: "Épreuves",
      component: ForecastContentsList
    },
    {
      path: "/forecasts/results",
      name: "Résultats",
      component: ForecastContentResultsList
    },
    {
      path: "/forecasts/attendees",
      name: "Participants",
      component: AttendeeLeaguesList,
    },
    {
      path: "/forecasts/leagues",
      name: "Ligues",
      component: ForecastLeaguesList
    },
    
  ]
};

const testProductsUsersRoutes = {
  path:"/testing-clients",
  name: "Testing produits",
  icon: UserCheck,
  component: TestProductsUsersList
}

const racesRoutes = {
  path: "/races",
  name: "Courses",
  icon: Flag,
  component: RacesList,
};

const qrCodesRoutes = {
  path: "/qr-codes",
  name: "QRCodes",
  icon: Code,
  component: QRCodesList,
};

const attendeeRewardsRoutes = {
  path: "/attendee-rewards",
  name: "Récompenses à envoyer",
  icon: Gift,
  component: AttendeeRewardsList,
}

const ambassadorRoutes = {
  path: "/ambassador",
  name: "Ambassadeurs",
  icon: Archive,
  children: [
    {
      path: "/ambassador/applied",
      name: "Candidatures",
      component: AmbassadorAppliedUsersList
    },
    {
      path: "/ambassador/approved",
      name: "Actifs",
      component: AmbassadorApprovedUsersList
    },
  ]
};

// Dashboard specific routes
export const allRoutes = [
  dashboardRoutes,
  dashboardAthletismeRoutes,
  dashboardTrailRoutes,
  dashboardRunningRoutes,
  detailedReportingRoutes,
  pageRoutes,
  categoryRoutes,
  declinaisonsRoutes,
  merchandsRoutes,
  physicalShopsRoutes,
  friendsReferredRoutes,
  vouchersRoutes,
  landingPagesRoutes,
  wheelsSpinRoutes,
  usersRoutes,
  clientsRoutes,
  stravaAthleteRoutes,
  physicalEventsRoutes,
  testProductsUsersRoutes,
  appearanceRoutes,
  newsletterRoutes,
  notificationsRoutes,
  podcastsRoutes,
  logoutRoutes,
  profileRoutes,
  productRatingsRoutes,
  racesRoutes,
  attendeeRewardsRoutes,
  gamesRoutes,
  rewardsRoutes,
  coinsRoutes,
  dynamicLinksRoutes,
  favoritesUsersRoutes,
  merchantPlacesRoutes,
  qrCodesRoutes,
  forecastsRoutes,
  ambassadorRoutes,
];

// Dashboard specific routes
export const dashboardAll = [
  dashboardRoutes,
  ambassadorRoutes,
  appearanceRoutes,
  productRatingsRoutes,
  categoryRoutes,
  clientsRoutes,
  stravaAthleteRoutes,
  friendsReferredRoutes,
  vouchersRoutes,
  racesRoutes,
  declinaisonsRoutes,
  dynamicLinksRoutes,
  physicalEventsRoutes,
  gamesRoutes,
  landingPagesRoutes,
  physicalShopsRoutes,
  newsletterRoutes,
  merchantPlacesRoutes,
  podcastsRoutes,
  pageRoutes,
  notificationsRoutes,
  favoritesUsersRoutes,
  forecastsRoutes,
  qrCodesRoutes,
  rewardsRoutes,
  attendeeRewardsRoutes,
  detailedReportingRoutes,
  wheelsSpinRoutes,
  merchandsRoutes,
  testProductsUsersRoutes,
  coinsRoutes,
  usersRoutes,
];

// Landing specific routes
export const landing = [loginRoutes];

// Auth specific routes

// All routes
export default [
  dashboardRoutes,
  dashboardAthletismeRoutes,
  dashboardRunningRoutes,
  dashboardTrailRoutes,
  detailedReportingRoutes,
  pageRoutes,
  categoryRoutes,
  declinaisonsRoutes,
  merchandsRoutes,
  physicalShopsRoutes,
  friendsReferredRoutes,
  vouchersRoutes,
  usersRoutes,
  physicalEventsRoutes,
  testProductsUsersRoutes,
  appearanceRoutes,
  newsletterRoutes,
  notificationsRoutes,
  podcastsRoutes,
  logoutRoutes,
  profileRoutes,
  productRatingsRoutes,
  clientsRoutes,
  stravaAthleteRoutes,
  gamesRoutes,
  racesRoutes,
  rewardsRoutes,
  coinsRoutes,
  dynamicLinksRoutes,
  merchantPlacesRoutes,
  qrCodesRoutes,
  forecastsRoutes,
  ambassadorRoutes,
];
