import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { Component }  from 'react';
import {Tab, Row, Col} from "react-bootstrap";
import Moment from "moment";
import Select from "react-select";
import NumericInput from 'react-numeric-input';


const options = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' }
]

export default class AddRewardModal extends Component {
    constructor(props) {
        console.log(props)
        super(props);
        this.state ={
            selectedLogo: this.props.selectedReward.logo,
            selectedCover: this.props.selectedReward.cover,
            giftToSend: this.props.selectedReward.giftToSend,
            giftToPhysicalSend: this.props.selectedReward.giftToPhysicalSend,
            isUniqueVoucher: this.props.selectedReward.isUniqueVoucher,
        };
    }

    handleClose = () => {
        this.props.selectedReward.giftToSend = this.state.giftToSend
        this.props.selectedReward.giftToPhysicalSend = this.state.giftToPhysicalSend
        this.props.selectedReward.isUniqueVoucher = this.state.isUniqueVoucher

        fetch('https://back-api.therunningcollective.fr/reward', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.props.selectedReward)
        })
            .then( () => (this.props.closeModal, window.location.reload(false)))
            .catch( err =>
                console.log(err)
            );
    }

    uploadLogo = async (e) => {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "rewards/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        this.props.selectedReward.logo = result.message
                        this.setState({selectedLogo: result.message})
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    uploadCover = async (e) => {
        const file = e.target.files[0]
        const fileName = e.target.files[0].name
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            fetch('https://back-api.therunningcollective.fr/upload-file', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    blob:reader.result,
                    fileName: "rewards/"+fileName
                })
            }).then((res) =>
                res.json().then( result =>{
                        sleep(2000)
                        this.props.selectedReward.cover = result.message
                        this.setState({selectedCover: result.message})
                    }
                )
            )
                .catch( err =>
                    console.log(err)
                );
        }
    }

    requestRegion = (event) => {
        this.props.selectedReward.minimumLevel = event.value
    }

    render(){

        return(
            <Modal
                show={this.props.isOpen}
                onHide={this.props.closeModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une récompense</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Form.Group >
                                            <Form.Label>Nom : </Form.Label>
                                            <Form.Control type="text" onChange={(e) => this.props.selectedReward.name = e.target.value} defaultValue={this.props.selectedReward.name} placeholder="Ex : Alltricks"/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Logo : </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadLogo(e)}
                                                custom
                                            />
                                            {this.state.selectedLogo ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedLogo}/>
                                            :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Image de couverture : </Form.Label>
                                            <Form.File
                                                id="custom-file-translate-html"
                                                label="Ajouter un image"
                                                data-browse="Ajouter"
                                                onChange= {(e) => this.uploadCover(e)}
                                                custom
                                            />
                                            {this.state.selectedCover ? <img alt="Logo..." className="imgToDisplay" src={this.state.selectedCover}/>
                                                :
                                                null
                                            }
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Petite Description : </Form.Label>
                                            <Form.Control as="textarea" rows={4} onChange={(e) => {this.props.selectedReward.description = e.target.value; this.setState({numberOfCharMetaDescription: e.target.value})}} defaultValue={this.props.selectedReward.description ? this.props.selectedReward.description : this.props.selectedReward.description} placeholder="5 dossards pour les 10km de Paris ..."/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Grosse description : </Form.Label>
                                            <Form.Control as="textarea" rows={4} onChange={(e) => {this.props.selectedReward.bigDescription = e.target.value; this.setState({numberOfCharMetaDescription: e.target.value})}} defaultValue={this.props.selectedReward.bigDescription ? this.props.selectedReward.bigDescription : this.props.selectedReward.bigDescription} placeholder="5 dossards pour les 10km de Paris ..."/>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label> Est-ce une récompense à envoyer par la poste ? </Form.Label>
                                            <Form.Check aria-label="availableSoon"
                                                        defaultChecked={this.props.selectedReward.giftToPhysicalSend}
                                                        onChange={(e) => this.setState({giftToPhysicalSend: e.target.checked})} />
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label> Est-ce une récompense à envoyer par mail ? </Form.Label>
                                            <Form.Check aria-label="availableSoon"
                                                        defaultChecked={this.props.selectedReward.giftToSend}
                                                        onChange={(e) => this.setState({giftToSend: e.target.checked})} />
                                        </Form.Group>
                                        {
                                            this.state.giftToSend ?
                                                <Form.Group >
                                                    <Form.Label> Est-ce un code promo unique ? </Form.Label>
                                                    <Form.Check aria-label="availableSoon"
                                                                defaultChecked={this.props.selectedReward.isUniqueVoucher}
                                                                onChange={(e) => this.setState({isUniqueVoucher: e.target.checked})} />
                                                </Form.Group>
                                                : null
                                        }
                                        {
                                            this.state.isUniqueVoucher && this.state.giftToSend ?
                                                <Form.Group >
                                                    <Form.Label>Code promo : </Form.Label>
                                                    <Form.Control type="text"
                                                                  onChange={(e) => this.props.selectedReward.voucher = e.target.value}
                                                                  defaultValue={this.props.selectedReward.voucher} placeholder="Ex: TRC5"/>
                                                </Form.Group> :
                                                this.state.giftToSend ?
                                                    <Form.Group >
                                                        <Form.Label>Liste de codes promos : (séparation par une virgule)</Form.Label>
                                                        <Form.Control as="textarea" rows={4}
                                                                      onChange={(e) => {this.props.selectedReward.vouchersList = e.target.value}}
                                                                      defaultValue={this.props.selectedReward.vouchersList ? this.props.selectedReward.vouchersList : this.props.selectedReward.vouchersList} placeholder="ABCDECCODEPROMO,"/>
                                                    </Form.Group>
                                                    : null
                                        }
                                        <Form.Group>
                                            <Form.Label>Nombre de récompense disponible : </Form.Label>
                                            <Form.Row>
                                                <NumericInput min={1}
                                                              max={100000}
                                                              value={this.props.selectedReward.winnersCount}
                                                              onChange={(value) => this.props.selectedReward.winnersCount = value}/>
                                                </Form.Row>
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Prix : </Form.Label>
                                            <Form.Row>
                                            <NumericInput min={1}
                                                          max={100000}
                                                          value={this.props.selectedReward.price}
                                                          onChange={(value) => this.props.selectedReward.price = value}/>
                                            </Form.Row>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Niveau minimum pour acquérir cette récompense : </Form.Label>
                                            <Form.Row>
                                                <Select
                                                    options={options}
                                                    defaultValue={this.props.selectedReward.minimumLevel ? { label: this.props.selectedReward.minimumLevel, value: this.props.selectedReward.minimumLevel } : { label: "0", value: 0 }}
                                                    onChange={(e) => this.requestRegion(e)}/>
                                            </Form.Row>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Date de début : </Form.Label>
                                            <Form.Control key="beginDate" type="datetime-local" name="begin" placeholder="Début"
                                                          defaultValue={Moment(this.props.selectedReward.beginDate).format('YYYY-MM-DDTHH:mm').toString()}
                                                          onChange={(e) => this.props.selectedReward.beginDate = new Date(e.target.value).toISOString()}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Date de fin : </Form.Label>
                                            <Form.Control key="endDate" type="datetime-local" name="end" placeholder="Fin"
                                                          defaultValue={Moment(this.props.selectedReward.endDate).format('YYYY-MM-DDTHH:mm').toString()}
                                                          onChange={(e) => this.props.selectedReward.endDate = new Date(e.target.value).toISOString()}
                                            />
                                        </Form.Group>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="cancel" onClick={this.props.closeModal}>
                        Annuler
                    </Button>
                    <Button variant="primary" type="submit" onClick={this.handleClose}>
                        Valider
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export function sleep(time){
    return new Promise((resolve)=>setTimeout(resolve,time)
    )
}