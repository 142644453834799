import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Edit2, Mail, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AddVoucherModalForm from "./modals/AddVoucherModal";
import Moment from "moment";
import DeleteAttendeeRewardModal from "./modals/DeleteAttendeeRewardModal";
import AddManualReporting from "./modals/AddManualReporting";
import AddAdidasVouchers from "./modals/AddAdidasVouchers";
import AddVoucherInfoModalForm from "./modals/AddVoucherInfoModal";
import UpdateRaceForm from "./modals/UpdateRaceModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {MdMail} from "react-icons/md";

function getAttendeeRewards() {

    return fetch('https://back-api.therunningcollective.fr/user-attendee-rewards', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function updateState(reward, checked) {
    reward.isProcessed = checked
    reward.treatmentAgentName = JSON.parse(localStorage.getItem('token')).login
    return fetch('https://back-api.therunningcollective.fr/user-attendee-reward', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(reward)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

function updateContactedState(reward, checked) {
    reward.isContacted = checked
    reward.treatmentAgentName = JSON.parse(localStorage.getItem('token')).login
    return fetch('https://back-api.therunningcollective.fr/user-attendee-reward', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(reward)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

export class AttendeeRewardsList extends Component{
    state = {
        rewards: [],
        errors: null,
        selectedReward: {},
    }

    async componentDidMount() {
        await getAttendeeRewards().then(data => {
            this.setState({ rewards: data })
        }).catch(err =>
                console.log(err),
            this.setState({errors: "error while getting rewards"})
        );
    };

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={6} className="products-buttons">
                        <span>Nombre de récompenses : {this.state.rewards.length}</span>
                    </Col>
                </Row>
                { (!this.state.rewards) ? <p> Aucun rewards à envoyer.</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Récompense</th>
                            <th>Utilisateur</th>
                            <th>Email</th>
                            <th>Date de validation</th>
                            <th>Contact</th>
                            <th>Utilisateur contacté ?</th>
                            <th>Recompense envoyée ?</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.rewards.length > 0 && this.state.rewards.map(reward => (
                            <tr key={reward.id}>
                                <td>
                                    <div>{reward.rewardName}</div>
                                    {reward.rewardDescription !== "" ? 
                                        <div>{reward.rewardDescription}</div>
                                : null}    
                                </td>
                                <td>
                                    <div>{reward.userInfo.firstName + " " + reward.userInfo.name}</div>
                                    {reward.userInfo.address !== "" ? 
                                        <div>{reward.userInfo.address + " " + reward.userInfo.zipCode+ " " + reward.userInfo.town + " " + reward.userInfo.country}</div>
                                : null}    
                                </td>
                                <td>{reward.userInfo.email}</td>
                                <td>{Moment(reward.createdDate).format('DD-MM-YYYY').toString()}</td>
                                <td className="table-action" style={{cursor: "pointer"}}>
                                    <Mail className="align-middle" size={18}
                                          onClick={() => window.location = 'mailto:'+reward.userInfo.email}/>
                                </td>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Utilisateur contacté?</Tooltip>}
                                >
                                    <td><BootstrapSwitchButton
                                        checked={reward.isContacted}
                                        onlabel='oui'
                                        onstyle='success'
                                        offlabel='non'
                                        offstyle='danger'
                                        size='xs'
                                        onChange={(checked: boolean) => {
                                            updateContactedState(reward, checked)
                                        }}
                                    /></td>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Récompense envoyée?</Tooltip>}
                                >
                                    <td><BootstrapSwitchButton
                                        checked={reward.isProcessed}
                                        onlabel='oui'
                                        onstyle='success'
                                        offlabel='non'
                                        offstyle='danger'
                                        size='xs'
                                        onChange={(checked: boolean) => {
                                            updateState(reward, checked)
                                        }}
                                    /></td>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Supprimer la récompense</Tooltip>}
                                >
                                    <td className="table-action" style={{cursor: "pointer"}}>
                                        <Trash className="align-middle" size={18}
                                               onClick={(e) => this.setState({isDelete: true, selectedReward: reward})}/>
                                    </td>
                                </OverlayTrigger>

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }

                { this.state.isVouchersOpen ?
                    <AddAdidasVouchers
                        isOpen={this.state.isVouchersOpen}
                        closeModal={this.closeModal}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteAttendeeRewardModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedReward}
                        selectedCategory="attendee-physical-event"
                    />
                    :
                    null
                }
            </div>
        );

    }

}