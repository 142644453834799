import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Edit2, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Moment from "moment";
import DeleteLandingPageContentModal from "./modals/DeleteLandingPageContentModal";
import AddLandingPageModal from "./modals/AddLandingPageModal";
import AddLandingPageContentModal from "./modals/AddLandingPageContentModal";
import {ReactSortable} from "react-sortablejs";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {MenuItem, Select} from "@material-ui/core";

function getLandingPages(type, landingPage) {
    if (landingPage && landingPage !== "Toutes") {
        return fetch('https://back-api.therunningcollective.fr/landing-page-contents?headCategory='+type+"&name="+landingPage, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
            },
        })
            .then(data => data.json())
            .catch( err =>
                console.log(err)
            )
    } else {
        return fetch('https://back-api.therunningcollective.fr/landing-page-contents?headCategory='+type, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
            },
        })
            .then(data => data.json())
            .catch( err =>
                console.log(err)
            )
    }

}

function getLandingPagesList() {

    return fetch('https://back-api.therunningcollective.fr/landing-pages', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

function updateState(slide, checked) {
    slide.enable = checked
    return fetch('https://back-api.therunningcollective.fr/landing-page-content', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(slide)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

export class LandingPagesContentList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isDelete: false,
            selectedLandingPage: "Toutes",
            errors: null,
            sliders: null,
            banners: null,
            landingPagesList: null,
            dragId: null,
            selectedLandingPageContent: {
                title:"",
                image: "",
                subtitle: "",
                textColor: {
                    r: 255,
                    g: 255,
                    b: 255,
                    a: 100,
                },
                buttonText: "",
                url: ""
            }
        }
    }

    async componentDidMount() {
        await getLandingPages("slider").then(data => {
            this.setState({sliders: data})
        }).catch(err =>
            this.setState({errors: "error while getting sliders"})
        );
        await getLandingPages("banner").then(data => {
            this.setState({banners: data})
        }).catch(err =>
            this.setState({errors: "error while getting sliders"})
        );
        await getLandingPagesList().then(data => {
            this.setState({landingPagesList: data})
        }).catch(err =>
            this.setState({errors: "error while getting sliders"})
        );
    };

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedLandingPage !== this.state.selectedLandingPage)  {
            await getLandingPages("slider", this.state.selectedLandingPage).then(data => {
                this.setState({sliders: data})
            }).catch(err =>
                this.setState({errors: "error while getting sliders"})
            );
            await getLandingPages("banner", this.state.selectedLandingPage).then(data => {
                this.setState({banners: data})
            }).catch(err =>
                this.setState({errors: "error while getting sliders"})
            );
        }
    }

    closeModal = () => this.setState({
        isOpenBanner: false,
        isOpenSlider: false,
        selectedLandingPageContent: {
            imgURL: "",
            text: "",
            order:0,
            textColor: {
                r: 255,
                g: 255,
                b: 255,
                a: 100,
            },
            buttonText: "",
            buttonURL: ""
        }
    });

    handleDropSlides = (list, type) => {
        const newBoxState = list.map((slide, index) => {
            slide.order = index

            fetch('https://back-api.therunningcollective.fr/landing-page-content', {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(slide)
            }).then(this.props.closeModal)
                .catch( err =>
                    console.log(err)
                )

            return slide;
        });

        if (type == "banners"){
            this.setState({banners : newBoxState});
        } else if (type == "sliders"){
            this.setState({sliders : newBoxState});
        }

    };

    render(){

        return(
            <div className="products-list">
                <Row>
                    <Col sm={2} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isOpenSlider : true})}>Ajouter une bannière</Button>
                    </Col>
                    <Col sm={2} className="products-buttons">
                        <Button className="add-product-button " variant="secondary" type="submit"onClick={(e) => this.setState({isOpenBanner : true})}>Ajouter une image</Button>
                    </Col>
                    <Col sm={4}>
                        <Select value={this.state.selectedLandingPage} onChange={(event) => {
                            this.setState({selectedLandingPage: event.target.value});
                        }}>
                            <MenuItem value={"Toutes"}>Toutes</MenuItem>
                            {
                                this.state.landingPagesList && this.state.landingPagesList.length > 0 ?
                                    this.state.landingPagesList.map(landingPage => {
                                        return(
                                            <MenuItem value={landingPage.title}>{landingPage.title}</MenuItem>
                                        )
                                    })
                                    : null
                            }
                        </Select>
                    </Col>
                </Row>
                { (!this.state.sliders && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <div>
                    <h1>Bannières déroulantes</h1>
                    <Table responsive>
                        <tbody>
                        <ReactSortable list={this.state.sliders} setList={(list) => this.handleDropSlides(list, "sliders")}>
                            {this.state.sliders && this.state.sliders.sort((a, b) => a.order > b.order).map(slide => (
                                <tr
                                    key={slide.order}
                                    id={slide.id}
                                    style={{textAlign: 'center'}}
                                >
                                    <td><BootstrapSwitchButton
                                        checked={slide.enable}
                                        onlabel='oui'
                                        onstyle='success'
                                        offlabel='non'
                                        offstyle='danger'
                                        size='xs'
                                        onChange={(checked: boolean) => {
                                            updateState(slide, checked)
                                        }}
                                    /></td>
                                    <td>
                                        {slide.shopTypeNG && slide.shopTypeNG.length > 0
                                            ?
                                            slide.shopTypeNG.map(slide => {
                                                return(
                                                    <div>{slide}</div>
                                                )
                                            })
                                            : null
                                        }
                                    </td>
                                    <td>{slide.title}</td>
                                    <td>
                                        <img style={{
                                            maxWidth: '100px',
                                        }} src={slide.image}/>
                                    </td>
                                    <td>{slide.subtitle}</td>
                                    <td> <div style={{
                                        width: '36px',
                                        height: '14px',
                                        borderRadius: '2px',
                                        backgroundColor: `rgba(${ slide.textColor.r }, ${ slide.textColor.g }, ${ slide.textColor.b }, ${ slide.textColor.a })`,
                                    }}></div></td>
                                    <td>{slide.buttonText}</td>
                                    <td>{slide.url}</td>
                                    <td className="table-action" style={{cursor: 'pointer'}}>
                                        <Edit2 className="align-middle mr-1" size={18}
                                               onClick={(e) => this.setState({list: this.state.landingPagesList, isOpenSlider: true, selectedLandingPageContent: slide})}/>
                                    </td>
                                    <td className="table-action" style={{cursor: 'pointer'}}>
                                        <Trash className="align-middle" size={18}
                                               onClick={(e) => this.setState({isDelete: true, selectedLandingPageContent: slide})}/>
                                    </td>
                                </tr>
                            ))}
                        </ReactSortable>
                        </tbody>
                    </Table>
                    </div>
                }

                { (!this.state.banners && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <div>
                        <h1>Images</h1>
                        <Table responsive>
                            <tbody>
                            <ReactSortable list={this.state.banners} setList={(list) => this.handleDropSlides(list, "banners")}>
                                {this.state.banners && this.state.banners.sort((a, b) => a.order > b.order).map(slide => (
                                    <tr
                                        key={slide.order}
                                        id={slide.id}
                                        style={{textAlign: 'center'}}
                                    >
                                        <td><BootstrapSwitchButton
                                            checked={slide.enable}
                                            onlabel='oui'
                                            onstyle='success'
                                            offlabel='non'
                                            offstyle='danger'
                                            size='xs'
                                            onChange={(checked: boolean) => {
                                                updateState(slide, checked)
                                            }}
                                        /></td>
                                        <td>
                                            {slide.shopTypeNG && slide.shopTypeNG.length > 0
                                                ?
                                                slide.shopTypeNG.map(slide => {
                                                    return(
                                                        <div>{slide}</div>
                                                    )
                                                })
                                                : null
                                            }
                                        </td>
                                        <td>{slide.title}</td>
                                        <td>
                                            <img style={{
                                                maxWidth: '100px',
                                            }} src={slide.image}/>
                                        </td>
                                        <td>{slide.subtitle}</td>
                                        <td> <div style={{
                                            width: '36px',
                                            height: '14px',
                                            borderRadius: '2px',
                                            backgroundColor: `rgba(${ slide.textColor.r }, ${ slide.textColor.g }, ${ slide.textColor.b }, ${ slide.textColor.a })`,
                                        }}></div></td>
                                        <td>{slide.buttonText}</td>
                                        <td>{slide.url}</td>
                                        <td className="table-action" style={{cursor: 'pointer'}}>
                                            <Edit2 className="align-middle mr-1" size={18}
                                                   onClick={(e) => this.setState({list: this.state.landingPagesList, isOpenBanner: true, selectedLandingPageContent: slide})}/>
                                        </td>
                                        <td className="table-action" style={{cursor: 'pointer'}}>
                                            <Trash className="align-middle" size={18}
                                                   onClick={(e) => this.setState({isDelete: true, selectedLandingPageContent: slide})}/>
                                        </td>
                                    </tr>
                                ))}
                            </ReactSortable>
                            </tbody>
                        </Table>
                    </div>
                }
                { this.state.isOpenBanner ?
                    <AddLandingPageContentModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpenBanner}
                        handleSubmit={this.handleSubmit}
                        selectedSlide={this.state.selectedLandingPageContent}
                        typeOfContent={"banner"}
                        list={this.state.landingPagesList}
                    />
                    :
                    null
                }
                { this.state.isOpenSlider ?
                    <AddLandingPageContentModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpenSlider}
                        handleSubmit={this.handleSubmit}
                        selectedSlide={this.state.selectedLandingPageContent}
                        typeOfContent={"slider"}
                        list={this.state.landingPagesList}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteLandingPageContentModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedLandingPageContent}
                        selectedCategory="landing-page-content"
                    />
                    :
                    null
                }
            </div>
        );

    }

}
