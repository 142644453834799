import React, {Component} from 'react';
import {Table} from "reactstrap";
import 'moment/locale/fr';
import './Products.css'
import {Download, Edit2, Mail, Trash} from "react-feather";
import {Col, OverlayTrigger, Row, ToggleButton, ToggleButtonGroup, Tooltip} from "react-bootstrap";
import AddUserModalForm from "./modals/AddUserModal";
import DeleteModal from "./modals/DeleteModal";
import Moment from "moment";
import CsvDownloadButton from 'react-json-to-csv'
import {AiOutlineInstagram, AiFillLinkedin} from "react-icons/ai";
import {BsFacebook, BsTiktok} from "react-icons/bs";
import {BiRun} from "react-icons/bi";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

function getAmbassadorAppliedUsers() {

    return fetch('https://back-api.therunningcollective.fr/ambassador-applied-front-users', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`
        },
    })
        .then(data => data.json())
        .catch( err =>
            console.log(err)
        )
}

export class AmbassadorAppliedUsersList extends Component {
    state = {
        errors: null,
        csvUsers: null,
    }

    async componentDidMount() {
        await getAmbassadorAppliedUsers().then(data => {
            const uniqueNames = data
                .map(e => e['email'])

                // store the keys of the unique objects
                .map((e, i, final) => final.indexOf(e) === i && i)

                // eliminate the dead keys & store unique objects
                .filter(e => data[e]).map(e => data[e]);
            this.setState({users: uniqueNames})
        }).catch(err =>
            this.setState({errors: "error while getting users"})
        );
    };


    render() {
        return (
            <div className="products-list">
                <Row>
                    <Col sm={11} className="products-buttons">
                        {(this.state.users && this.state.errors == null) ? <span>{this.state.users.length} comptes client</span> : null }
                    </Col>
                    <Col xs={1}>
                        <CsvDownloadButton
                            data={this.state.csvUsers}
                            filename={"front_users_"+ new Date().toLocaleDateString()+"_"+new Date().toLocaleTimeString()+".csv"}
                            style={{
                                background: "none",
                                border: "none"
                            }}
                        >
                            <Download style={{cursor:"pointer"}}/>
                        </CsvDownloadButton>
                    </Col>
                </Row>

                { (!this.state.users && this.state.errors == null) ? <p> Chargement ...</p>
                    :
                    <Table responsive>
                        <thead>
                        <tr>
                            <th></th>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Sexe</th>
                            <th>Âge</th>
                            <th>Ville</th>
                            <th>Email</th>
                            <th>Profil</th>
                            <th>Réseaux</th>
                            <th>Taille chaussures</th>
                            <th>Taille vêtements</th>
                            <th>Distance</th>
                            <th>Profession</th>
                            <th>Motivation</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.users.map(user => (
                            <tr>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip">Ambassadeur approuvé?</Tooltip>}
                                >
                                    <td><BootstrapSwitchButton
                                        checked={user.isAmbassadorApproved}
                                        onlabel='oui'
                                        onstyle='success'
                                        offlabel='non'
                                        offstyle='danger'
                                        size='xs'
                                        onChange={(checked: boolean) => {
                                            updateContactedState(user, checked)
                                        }}
                                    /></td>
                                </OverlayTrigger>
                                <td>{user.lastName}</td>
                                <td>{user.firstName}</td>
                                <td>{user.gender}</td>
                                <td>{calculateAge(user.birthdayDate) + " ans"}</td>
                                <td>{user.town}</td>
                                <td className="table-action" style={{cursor: "pointer"}}>
                                    <Mail className="align-middle" size={18}
                                          onClick={() => window.location = 'mailto:'+user.email}/>
                                </td>
                                <td>{user.type}</td>
                                <td>
                                    {user.instagramLink != "" ? <a href={user.instagramLink} target="_blank"><AiOutlineInstagram size="1rem"/></a> : null}
                                    {user.facebookLink != "" ? <a href={user.facebookLink} target="_blank"><BsFacebook size="1rem"/></a> : null }
                                    {user.linkedInLink != "" ? <a href={user.linkedInLink} target="_blank"><AiFillLinkedin size="1rem"/></a> : null }
                                    {user.tiktokLink != "" ? <a href={user.tiktokLink} target="_blank"><BsTiktok size="1rem"/></a> : null }
                                </td>
                                <td>{user.shoeSize}</td>
                                <td>{user.apparelSize}</td>
                                <td>{user.favoriteDistance}</td>
                                <td>{user.work}</td>
                                <td>{user.motivation}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                }
                {this.state.isOpen ?
                    <AddUserModalForm
                        closeModal={this.closeModal}
                        isOpen={this.state.isOpen}
                        handleSubmit={this.handleSubmit}
                        selectedUser={this.state.selectedUser}
                    />
                    :
                    null
                }
                {this.state.isDelete ?
                    <DeleteModal
                        closeModal={this.closeModal}
                        isOpen={this.state.isDelete}
                        handleSubmit={this.handleSubmit}
                        selectedItem={this.state.selectedUser}
                        selectedCategory="user"
                    />
                    :
                    null
                }
            </div>
        );

    }
}

function updateContactedState(user, checked) {
    user.isAmbassadorApproved = checked
    return fetch('https://back-api.therunningcollective.fr/ambassador-approved-front-user', {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token')).token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    }).then()
        .catch( err =>
            console.log(err)
        )
}

const calculateAge = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
    {
        age_now--;
    }
    console.log(age_now);
    return age_now;
}